import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "@carousel/slick.css"
import "@carousel/slick-theme.css"
import "./carousel/slick.css"
import "./carousel/slick-theme.css"
import { StaticImage } from "gatsby-plugin-image"
import DiegoCredit from "./DiegoCredit"

import {
  StyledFooter,
  StyledNavigation,
  StyledNumber,
} from "./footer/StyledFooter"
import GalleryList from "./footer/GalleryList"
import Contact from "./footer/Contact"
import WrapperTemplate, { StyledPicture } from "./WrapperTemplate"

const StyledWrapper = styled(WrapperTemplate)`
  ${({ picture }) => handleTitleHovered(picture)};
  #link-two {
    font-style: italic;
    pointer-events: none;
  }
`

const handleTitleHovered = picture => {
  switch (picture) {
    case "one":
      return `#cover-one {opacity:1} .current-slider{opacity:0}`
    case "two":
      return `#cover-two {opacity:1} .current-slider{opacity:0}`
    case "three":
      return `#cover-three {opacity:1} .current-slider{opacity:0}`
    case "four":
      return `#cover-four {opacity:1} .current-slider{opacity:0}`

    default:
      return `.current-slider{opacity:1}`
  }
}

export default function WrapperOrigins() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const imgsettings = {
    className: "image",
    height: 1600,
    quality: 70,
    objectFit: "contain",
    placeholder: "none",
    alt: "image",
  }
  // This create the carousel "states" we will later connect
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1 = []
  let slider2 = []
  // This binds the carousels together
  useEffect(() => {
    setNav1(slider1)
    setNav2(slider2)
  }, [slider1, slider2])

  // This part is for the hovering
  const [hoveredTitle, toggleHover] = useState("")

  function oneHover() {
    toggleHover("one")
  }

  function threeHover() {
    toggleHover("three")
  }
  function fourHover() {
    toggleHover("four")
  }
  function exitHover() {
    toggleHover("")
  }

  //

  return (
    <>
      <StyledWrapper className="gallery" picture={hoveredTitle}>
        <DiegoCredit />
        <StyledPicture className="cover-pic" id="cover-one">
          <StaticImage {...imgsettings} src="../images/horizons_10.jpg" />
        </StyledPicture>
        <StyledPicture className="cover-pic" id="cover-three">
          <StaticImage {...imgsettings} src="../images/anecdotes_12.jpg" />
        </StyledPicture>
        <StyledPicture className="cover-pic" id="cover-four">
          <StaticImage {...imgsettings} src="../images/goodbye_10.jpg" />
        </StyledPicture>
        <Slider
          id="keyslide"
          asNavFor={nav2}
          ref={slider => (slider1 = slider)}
          {...settings}
          className="current-slider"
        >
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_1.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_2.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_3.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_4.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_5.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_6.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_7.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_8.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_9.jpg" />
          </StyledPicture>
          <StyledPicture>
            <StaticImage {...imgsettings} src="../images/origins_10.jpg" />
          </StyledPicture>
        </Slider>
        <StyledFooter>
          <Contact />
          <StyledNavigation>
            <GalleryList
              oneHover={oneHover}
              twoHover=""
              threeHover={threeHover}
              fourHover={fourHover}
              exitHover={exitHover}
            />
            <StyledNumber>
              <Slider
                autoFocus
                asNavFor={nav1}
                autoFocus
                ref={slider => (slider2 = slider)}
                {...settings}
              >
                <p>01</p>
                <p>02</p>
                <p>03</p>
                <p>04</p>
                <p>05</p>
                <p>06</p>
                <p>07</p>
                <p>08</p>
                <p>09</p>
                <p>10</p>
              </Slider>
            </StyledNumber>
          </StyledNavigation>
        </StyledFooter>
      </StyledWrapper>
    </>
  )
}
